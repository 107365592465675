import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./cssStyles/about_us.css";

const About = () => {
  return (
    <Container id="about">
      <Row>
        <Col className="col-lg-4">
          <img
            className="rounded-circle"
            src="/images/druk3d.webp"
            alt="3D printing icon"
            width="200"
            height="200"
          />
          <h2 className="display-5 fw-bold lh-3 mb-3">Druk 3D</h2>
          <p className="lead h6">
            Zajmujemy się projektowaniem i tworzeniem trudno dostępnych
            elementów mających zastosowanie w medycynie w technice druku 3D
          </p>
        </Col>
        <Col className="col-lg-4">
          <img
            className="rounded-circle"
            src="/images/naprawa.webp"
            alt="Generic placeholder image"
            width="200"
            height="200"
          />
          <h2 className="display-5 fw-bold lh-3 mb-3">Naprawy</h2>
          <p className="lead h6">
            Wykonujemy naprawy sprzętu medycznego takiego jak: monitory
            pacjenta, defibrylatory, elektrokardiografy, pulsoksymetry, pompy
            infuzyjne itp.
          </p>
        </Col>
        <Col className="col-lg-4">
          <img
            className="rounded-circle"
            src="/images/sprawdz.webp"
            alt="Generic placeholder image"
            width="200"
            height="200"
          />
          <h2 className="display-5 fw-bold lh-3 mb-3">Przeglądy</h2>
          <p className="lead h6">
            Wykonujemy przeglądy techniczne i okresowe aparatury medycznej
            zgodnie z PN EN 62353, PN EN 60601
          </p>
        </Col>
      </Row>
      <hr className="featurette-divider" />
    </Container>
  );
};
export default About;
