// 20230605051052
// https://raw.githubusercontent.com/deldersveld/topojson/master/countries/poland/poland-provinces.json
const mapdata = {
  data: {
    type: "Topology",
    arcs: [
      [
        [8180, 4926],
        [-33, -14],
        [-51, -148],
        [-108, -26],
        [-53, -60],
        [-89, -64],
        [-69, 13],
        [-39, 23],
        [-85, -12],
        [-63, -126],
        [5, -104],
        [27, -37],
        [54, -24],
        [20, -28],
        [2, -102],
        [-22, -76],
        [-45, 14],
        [-115, 114],
        [-80, 5],
        [-14, -115],
        [10, -123],
        [-45, -60],
        [-49, -31],
        [-41, -71],
      ],
      [
        [7297, 3874],
        [-41, 13],
        [-64, 57],
        [-91, 19],
        [-30, -16],
        [-80, 2],
        [-18, 19],
        [-68, 127],
        [-44, 65],
        [-92, 86],
        [-73, 7],
        [-27, -35],
        [-31, -8],
        [-94, 2],
        [-56, 9],
        [-80, 31],
        [-114, 74],
        [-57, 57],
        [-47, -13],
        [-36, -40],
        [-134, -14],
        [-117, 1],
        [-26, -29],
      ],
      [
        [5877, 4288],
        [-118, 102],
        [-16, 65],
        [-39, 24],
        [-26, -51],
        [-113, -2],
        [-98, 30],
        [-64, 29],
        [-141, 33],
        [-40, 75],
      ],
      [
        [5222, 4593],
        [-11, 144],
        [-102, 105],
        [-12, 96],
        [11, 60],
        [106, 25],
        [35, 55],
        [22, 74],
        [46, 25],
        [67, -48],
        [63, 13],
        [32, 68],
        [26, 93],
        [-19, 179],
        [2, 180],
        [47, 157],
        [43, 115],
        [47, 58],
        [38, 19],
        [69, 9],
        [130, -17],
        [24, -12],
        [57, 103],
        [26, 73],
        [-32, 110],
        [-12, 67],
        [27, 141],
        [83, -2],
        [123, 41],
        [31, 52],
        [18, 192],
        [7, 20],
        [86, 18],
        [98, 74],
        [32, 42],
        [-25, 18],
        [-33, 58],
        [4, 69],
      ],
      [
        [6376, 7067],
        [45, 53],
        [53, -4],
        [48, 11],
        [64, -5],
        [43, 13],
        [11, 38],
        [67, 15],
      ],
      [
        [6707, 7188],
        [29, -35],
        [79, -59],
        [106, -10],
        [109, -42],
        [63, -46],
        [43, -58],
        [40, -8],
        [105, 42],
        [144, 24],
        [54, 56],
        [35, -8],
        [71, -110],
        [59, -9],
        [68, -50],
        [6, -115],
        [10, -40],
        [36, -51],
        [38, 19],
        [83, -39],
        [64, -54],
        [-16, -115],
        [-55, -27],
        [26, -49],
        [44, -37],
        [29, -71],
        [-3, -53],
        [78, -22],
        [21, 14],
        [96, 4],
        [61, -18],
        [60, -62],
        [78, -58],
        [44, -66],
        [14, -61],
        [-28, -30],
        [-4, -69],
        [23, -42],
        [46, -40],
        [31, -61],
        [-3, -62],
        [-14, -36],
        [-71, 4],
        [-99, 53],
        [-101, 14],
        [-34, -38],
        [-11, -75],
        [53, -137],
        [10, -146],
        [71, -41],
        [2, -91],
        [-26, -55],
        [-51, -32],
        [-36, -59],
        [-4, -105],
      ],
      [
        [10025, 4397],
        [5, -66],
        [-10, -77],
        [1, -81],
        [22, -99],
        [-1, -61],
        [24, -79],
        [30, -52],
      ],
      [
        [10096, 3882],
        [-22, -31],
        [-13, -114],
        [-19, -77],
        [-33, -81],
        [-29, -46],
        [-111, -123],
        [-104, -144],
        [-52, -40],
        [-100, -58],
        [-51, -40],
        [-43, -73],
        [-43, -23],
        [-73, -8],
        [-65, -64],
        [-53, -120],
      ],
      [
        [9285, 2840],
        [-214, -24],
        [-74, -37],
        [-55, 3],
        [-133, -22],
        [-115, -37],
        [-56, -56],
        [-86, -139],
        [-46, -22],
        [-122, 14],
        [-126, 0],
        [-47, 14],
        [-35, 42],
        [-40, 89],
        [-30, 32],
        [-33, 65],
        [-10, 65],
        [-31, 83],
        [-47, 94],
        [-40, 38],
        [-50, 27],
        [-46, -1],
        [-55, 22],
        [-39, 53],
        [-37, 30],
        [-76, 13],
        [-78, -5],
      ],
      [
        [7564, 3181],
        [-44, 26],
        [-78, 4],
        [-48, 25],
        [17, 51],
        [83, 92],
        [67, 5],
        [-65, 85],
        [-22, 68],
        [-64, 63],
        [-35, 1],
        [-82, 28],
        [-7, 54],
        [34, 71],
        [73, 23],
        [35, -3],
        [1, 66],
        [-79, 32],
        [-53, 2],
      ],
      [
        [8180, 4926],
        [70, -14],
        [39, -86],
        [5, -39],
        [50, -23],
        [180, -115],
        [13, -34],
        [51, -65],
        [63, -25],
        [44, 7],
        [57, -15],
        [172, 20],
        [75, 55],
        [31, 73],
        [68, 4],
        [6, -87],
        [38, -46],
        [4, -50],
        [29, -48],
        [36, -25],
        [178, -20],
        [58, -21],
        [39, -31],
        [80, -24],
        [101, 43],
        [120, 23],
        [238, 14],
      ],
      [
        [3663, 9904],
        [46, -39],
        [75, -139],
        [57, -36],
        [70, -9],
        [128, 17],
        [89, -24],
        [69, -47],
        [49, -57],
      ],
      [
        [4246, 9570],
        [-22, -66],
        [-115, -144],
        [7, -47],
        [94, -73],
        [66, -66],
        [31, -68],
        [-5, -51],
        [-38, -13],
        [-55, -41],
        [-30, -37],
        [31, -124],
        [36, -48],
        [-7, -70],
        [-79, -187],
        [37, -19],
        [80, -12],
        [63, -55],
        [61, -76],
        [24, -53],
        [3, -130],
        [-8, -38],
        [-42, -64],
        [-33, 6],
        [-51, -55],
        [46, -46],
        [63, -113],
        [83, -4],
        [42, 39],
        [54, -63],
        [6, -51],
        [28, -29],
        [70, -19],
        [86, 6],
        [93, -42],
        [75, -62],
        [71, -73],
        [82, -39],
        [50, 3],
        [37, -58],
        [53, -46],
        [123, -36],
        [50, 19],
        [51, 52],
        [23, 43],
        [46, -12],
        [6, -40],
        [51, -92],
        [40, -1],
        [18, 36],
        [74, 12],
        [64, -72],
        [125, -111],
        [69, -48],
        [39, -51],
        [5, -41],
        [102, 14],
        [38, 24],
        [106, 34],
        [113, -105],
      ],
      [
        [5222, 4593],
        [-61, -42],
        [-110, -62],
        [-126, -48],
        [-48, 3],
        [-95, 26],
        [-15, 40],
        [-2, 63],
        [-20, 30],
        [-51, 27],
        [-37, -7],
      ],
      [
        [4657, 4623],
        [-21, 224],
        [-18, 109],
        [44, 89],
        [-51, 45],
        [-41, 10],
        [-125, -14],
        [-41, 20],
        [-20, 59],
        [-12, 94],
        [16, 69],
        [47, 26],
        [17, 103],
        [-96, 75],
        [-103, 38],
        [-106, 19],
        [-173, -15],
        [-28, -36],
        [-6, -62],
        [-29, -24],
        [-176, -30],
        [-29, -11],
        [-89, -5],
        [-66, 43],
        [-39, 2],
        [-62, 91],
        [-40, 13],
        [-82, 51],
        [-68, 73],
        [-12, 140],
        [-21, 27],
        [-194, 98],
        [-118, -23],
      ],
      [
        [2915, 5921],
        [-28, 136],
        [-26, 58],
        [-76, 65],
        [-56, -61],
        [-39, -7],
        [-64, 27],
        [-57, 42],
        [-9, 23],
        [8, 108],
        [15, 43],
        [-31, 48],
        [-54, -24],
        [-64, 66],
        [-53, 128],
        [-52, 15],
        [-63, 66],
        [-21, 41],
        [-6, 135],
        [17, 30],
        [1, 75],
        [37, 98],
        [26, 110],
        [-6, 56],
        [-90, 170],
        [-3, 32],
        [66, -6],
        [-7, 154],
        [-29, 111],
        [-37, 82],
        [-6, 129],
        [10, 40],
        [-4, 66],
        [174, 56],
        [21, 40],
        [-24, 92],
        [-43, 85],
        [116, 99],
        [5, 24],
        [-1, 194],
        [22, 77],
      ],
      [
        [2484, 8644],
        [9, 10],
        [100, -71],
        [99, -12],
        [128, 53],
        [36, 25],
        [67, 75],
        [50, 107],
        [59, 77],
        [56, 47],
        [98, 36],
        [32, 23],
        [95, 27],
        [25, 19],
        [25, 51],
        [66, 89],
        [-46, 81],
        [-30, 32],
        [-104, 24],
        [-84, 36],
        [-42, 90],
        [-4, 129],
        [21, 38],
        [162, -2],
        [121, -8],
        [9, 13],
        [25, 136],
        [40, 99],
        [35, 50],
        [97, 4],
        [34, -18],
      ],
      [
        [6490, 9834],
        [115, -225],
        [10, -41],
        [36, -67],
        [8, -44],
        [41, -48],
        [38, 4],
        [97, -30],
        [63, -30],
        [83, -66],
        [52, -20],
        [53, -1],
        [108, -55],
        [25, -33],
        [35, -80],
        [31, -130],
        [15, -95],
        [-7, -41],
      ],
      [
        [7293, 8832],
        [-128, -82],
        [8, -124],
        [11, -43],
        [-26, -112],
        [-71, 40],
        [-41, -44],
        [-31, -55],
        [-54, -15],
        [-43, 13],
        [28, -145],
        [-55, -72],
        [4, -40],
        [75, -145],
        [13, -53],
        [-9, -38],
        [-54, 1],
        [-21, -35],
        [-15, -62],
        [-60, -184],
        [-44, -98],
        [5, -48],
        [40, -50],
        [-22, -52],
        [-87, -137],
        [-9, -64],
      ],
      [
        [4246, 9570],
        [37, 14],
        [22, 112],
        [55, 120],
        [26, 36],
        [86, -3],
        [49, -29],
        [102, -17],
        [54, 13],
        [33, 126],
        [30, 34],
        [104, 33],
        [60, 117],
        [77, 9],
        [46, -26],
        [58, -7],
        [150, 27],
        [124, 4],
        [75, -87],
        [57, -16],
        [120, -11],
        [125, -46],
        [23, -37],
        [101, 50],
        [98, 17],
        [76, -26],
        [111, -122],
        [106, -62],
        [47, -7],
        [46, 18],
        [97, 4],
        [49, 26],
      ],
      [
        [9285, 2840],
        [-15, -59],
        [-40, -85],
        [-42, -66],
        [-1, -102],
        [14, -69],
        [3, -107],
        [22, -107],
        [-2, -85],
        [-12, -90],
        [8, -75],
        [31, -66],
        [53, -2],
        [63, -50],
        [3, -29],
        [-122, -107],
        [-34, -66],
        [66, -53],
        [91, -38],
        [81, -68],
        [22, -45],
        [31, -103],
        [18, -88],
        [-12, -109],
        [16, -115],
        [55, -179],
      ],
      [
        [9582, 877],
        [-35, 17],
        [-15, -30],
        [-55, 3],
        [-51, 47],
        [-24, -5],
        [-40, 37],
        [-29, -6],
        [-55, 25],
        [-71, -73],
        [3, -21],
        [-48, -34],
        [-47, 56],
        [-40, 20],
        [-97, -41],
        [9, -30],
        [59, -50],
        [-31, -38],
        [-39, 8],
        [-76, -90],
        [-1, -32],
        [-74, -26],
        [-25, 17],
        [-60, 87],
        [-38, -22],
        [-69, 87],
        [-30, -5],
        [-18, 86],
        [-73, 13],
        [-34, -35],
        [-64, 27],
        [-25, -65],
        [-23, -16],
        [-87, 35],
        [-2, 20],
        [-67, 28],
        [-28, -39],
        [-69, -13],
        [-70, 31],
        [-9, -35],
        [4, -92],
        [-104, 5],
        [-56, -18],
        [-38, -44],
        [-29, -1],
        [-22, -51],
        [-4, -49],
        [-32, -52],
        [0, -54],
        [-17, -38],
        [-11, -66],
        [-77, 48],
        [-48, 67],
        [-80, 10],
        [-31, -45],
        [-44, -39],
        [-100, -7],
        [-26, 10],
        [8, 88],
        [36, 59],
        [-1, 234],
        [-9, 59],
        [-54, 9],
        [-35, -49],
        [-27, 30],
        [-80, 28],
        [-1, 79],
        [-44, -1],
        [-54, 68],
        [-36, 117],
        [-10, 78],
        [-81, 76],
      ],
      [
        [6931, 1274],
        [-51, 17],
        [-33, 60],
        [36, 86],
        [5, 72],
        [-51, -1],
        [-109, 51],
        [-20, 33],
        [-35, 110],
        [-95, 44],
        [-17, 27],
        [0, 89],
        [-16, 59],
        [-39, -10],
        [-37, 48],
        [11, 68],
        [47, 101],
        [66, 66],
        [137, 165],
        [119, 152],
        [-42, 72],
        [-88, 92],
        [50, 37],
        [85, -22],
        [29, 64],
        [31, 42],
        [53, 120],
        [79, -46],
        [97, 20],
        [60, 68],
        [49, 30],
        [108, -9],
        [56, 2],
        [42, 51],
        [88, 37],
        [33, 30],
        [-15, 82],
      ],
      [
        [4657, 4623],
        [-65, -40],
        [-80, 3],
        [-72, -51],
        [-15, -54],
        [-61, -153],
        [-19, -124],
        [-15, -37],
        [-66, -34],
        [-29, -47],
        [-20, -65],
        [-67, -139],
        [-115, -150],
        [-29, -58],
        [-55, -201],
        [-29, -86],
        [-65, -4],
        [-80, -31],
        [-98, -214],
        [-13, -70],
      ],
      [
        [3664, 3068],
        [-37, 28],
        [-54, -13],
        [-16, -59],
        [11, -29],
        [46, -27],
        [3, -68],
        [19, -19],
        [2, -49],
        [19, -28],
        [84, -35],
        [14, -127],
        [-8, -39],
        [-73, 16],
        [-82, -4],
        [-16, -50],
        [-99, -24],
        [-24, -79],
        [-34, -41],
        [-27, -2],
        [-16, -49],
        [-27, -21],
        [-45, -3],
        [-46, 17],
        [-29, 48],
        [-37, 17],
        [-2, 39],
        [-28, 18],
        [-6, 137],
        [-46, 26],
        [-12, 49],
        [-56, 45],
        [-2, 30],
        [-39, 56],
        [-64, 6],
        [-29, 38],
        [-3, 58],
        [-19, 28],
        [-100, -15],
        [-31, 76],
        [-45, -3],
        [-14, 88],
        [88, 73],
        [81, 34],
        [36, 2],
        [16, 40],
        [27, 12],
        [23, 99],
        [45, 12],
        [-1, 29],
        [-67, 65],
        [-21, 48],
        [-58, 55],
        [-54, -13],
        [-38, 27],
        [-37, -2],
        [-14, -69],
        [-54, -23],
        [-68, 74],
        [-46, -3],
        [-43, -61],
        [2, -39],
        [-81, -15],
        [3, 117],
        [-31, 67],
        [-55, -4],
        [-18, -20],
        [-81, -15],
        [-4, 68],
        [-39, 46],
        [-3, 49],
        [-45, -13],
        [-128, -8],
        [-12, 38],
        [-65, 15],
        [-48, 36],
        [-92, -7],
        [-11, 29],
        [-45, -3],
        [-40, 36],
        [-35, -22],
        [-25, -41],
        [-37, 7],
        [-9, 136],
        [-39, 37],
        [-37, 7],
        [-42, 85],
        [-5, 77],
        [26, 22],
        [-23, 67],
        [-37, 7],
        [-11, 28],
        [-82, -25],
        [-5, 77],
        [-44, -23],
        [1, -29],
        [-55, 6],
        [6, 39],
        [-73, -5],
        [-39, 26],
        [-35, -32],
        [-15, -50],
        [46, 3],
        [7, -107],
        [-44, -32],
        [22, -48],
        [4, -68],
        [-73, -15],
        [-38, 26],
        [-110, -19],
        [-12, 50],
        [66, 74],
        [9, 40],
        [27, 10],
        [6, 49],
        [34, 42],
        [4, 79],
        [23, 63],
        [34, 49],
        [-4, 68],
        [29, 53],
        [-6, 56],
        [21, 61],
        [-3, 48],
        [42, 62],
        [-6, 119],
        [-44, 79],
        [-30, 23],
        [-12, 46],
        [3, 87],
      ],
      [
        [1098, 5172],
        [104, 73],
        [58, 15],
        [67, 34],
        [32, -17],
        [40, -46],
        [58, -24],
        [79, 152],
        [33, 29],
        [80, 40],
        [31, -6],
        [163, -112],
        [30, 0],
        [62, 29],
        [51, 78],
        [80, 98],
        [68, 123],
        [65, 148],
        [19, 26],
        [69, 22],
        [31, 39],
        [30, 91],
        [40, 7],
        [97, -18],
        [101, -37],
        [10, -14],
        [2, -88],
        [11, -56],
        [74, -47],
        [71, 20],
        [96, 71],
        [65, 119],
      ],
      [
        [11769, 7107],
        [26, -41],
        [-9, -94],
        [17, -23],
        [67, -19],
        [55, 17],
        [2, -30],
        [80, 1],
        [35, -15],
        [3, -53],
        [56, 16],
        [36, -21],
        [19, -45],
        [35, -9],
        [36, -59],
        [64, 5],
        [12, -38],
        [39, -23],
        [19, -55],
        [12, -97],
        [31, -44],
        [-32, -34],
        [-30, -59],
        [9, -42],
        [-42, -37],
        [31, -51],
        [-37, -41],
        [10, -55],
        [-19, -51],
        [57, -60],
        [-21, -59],
        [-77, -46],
        [-33, -79],
        [31, -16],
        [-13, -54],
        [-15, -190],
        [32, -40],
        [-9, -93],
        [49, -5],
        [38, -45],
        [-18, -39],
        [64, -30],
        [7, -40],
        [-30, -40],
        [46, -14],
        [17, -48],
        [-21, -56],
        [-4, -60],
        [-29, -18],
        [-21, -57],
        [-3, -49],
        [21, -60],
        [86, -34],
        [6, -69],
        [26, -50],
        [83, -70],
        [13, -35],
        [64, -31],
        [2, -36],
        [-27, -15],
        [25, -95],
        [52, -6],
        [6, -61],
        [20, -66],
        [-11, -29],
        [45, -12],
        [25, -60],
        [-2, -39],
        [27, -22],
        [42, -71],
        [80, -44],
        [35, -41],
        [46, -3],
        [-2, -29],
        [-57, -45],
        [-147, 9],
        [-31, -57],
        [5, -88],
        [63, -14],
        [-5, -97],
        [73, -5],
        [-22, -67],
        [43, -61],
        [-3, -59],
        [17, -20],
        [-17, -146],
        [-22, -67],
        [-6, -107],
        [-31, -76],
        [-48, -36],
        [-83, -34],
        [-110, 6],
        [-63, 14],
        [-55, -16],
        [-67, -65],
        [-23, -86],
        [-47, -39],
      ],
      [
        [12367, 2838],
        [-108, 56],
        [-37, 30],
        [-51, 104],
        [-164, 29],
        [-47, -14],
        [-45, 3],
        [-124, -148],
        [-76, -67],
        [-61, -17],
        [-82, -3],
        [-68, 14],
        [-75, -13],
        [-107, 7],
        [-192, 62],
        [9, 35],
        [55, -2],
        [0, 27],
        [-51, 17],
        [-65, -29],
        [-68, -15],
        [-44, 5],
        [-51, 49],
        [-26, 78],
        [50, 88],
        [41, 20],
        [59, -8],
        [35, 36],
        [-46, 67],
        [-13, 66],
        [4, 41],
        [-207, 123],
        [-134, 29],
        [-62, 26],
        [-116, 27],
        [-27, 41],
        [69, 141],
        [12, 35],
        [-81, 65],
        [-43, 23],
        [-64, -2],
        [-56, -46],
        [-57, -26],
        [-61, 10],
        [-60, 36],
        [-36, 44],
      ],
      [
        [10025, 4397],
        [-28, 140],
        [-35, 114],
        [0, 36],
        [31, 190],
        [8, 74],
        [21, 106],
        [-59, 77],
        [14, 54],
        [53, 31],
        [8, 48],
        [-10, 129],
        [-21, 72],
        [-22, 14],
        [-118, 37],
        [-49, 2],
        [-45, 33],
        [-15, 32],
        [5, 87],
        [34, 21],
        [130, -38],
        [52, 4],
        [64, 26],
        [37, 30],
        [12, 48],
        [-70, 123],
        [37, 51],
        [30, 8],
        [55, 46],
        [-76, 124],
        [1, 67],
        [-25, 55],
        [-41, 132],
        [123, 92],
        [43, 12],
        [127, -6],
        [168, 1],
        [92, 38],
        [90, -16],
        [87, 70],
        [79, 53],
        [57, -9],
        [153, -3],
        [35, 31],
        [12, 79],
        [48, 45],
        [112, -39],
        [21, -27],
        [85, -28],
        [34, 7],
        [69, 92],
        [15, 64],
        [160, 245],
        [15, 51],
      ],
      [
        [11628, 7122],
        [141, -15],
      ],
      [
        [1098, 5172],
        [-13, 59],
        [6, 49],
        [-39, 62],
        [-31, 24],
        [-106, 18],
        [-54, 44],
        [-72, 14],
        [-3, 49],
        [-40, 17],
        [32, 81],
        [30, 32],
        [-12, 49],
        [2, 64],
        [-25, 65],
        [-50, 39],
        [-33, 65],
        [-8, 117],
        [-68, 24],
        [-6, 77],
        [80, 75],
        [35, 62],
        [32, 82],
        [-17, 88],
        [55, 138],
        [9, 49],
        [-33, 56],
        [-34, 8],
        [-26, 48],
        [3, 65],
        [30, 49],
        [-26, 48],
        [22, 32],
        [10, 65],
        [-19, 40],
        [-56, 8],
        [-49, 22],
        [-43, 35],
        [19, 27],
        [-46, 104],
        [-5, 67],
        [-22, 33],
        [16, 32],
        [2, 57],
        [40, 15],
        [62, 87],
        [-20, 135],
        [35, 42],
        [-1, 29],
        [-32, 35],
      ],
      [
        [629, 7754],
        [40, 149],
        [24, 16],
        [147, 21],
        [55, 45],
        [31, 48],
        [92, 183],
        [53, 119],
        [78, 27],
        [85, -12],
        [57, -35],
        [78, -19],
        [85, -6],
        [86, 101],
        [28, 66],
        [-40, 70],
        [111, 4],
        [134, 70],
        [102, 11],
        [124, -20],
        [76, 9],
        [72, 38],
        [107, 153],
        [89, 70],
        [126, 0],
        [35, -72],
        [3, -109],
        [-23, -37],
      ],
      [
        [7293, 8832],
        [75, 21],
        [26, 79],
        [48, 31],
        [58, -20],
        [19, -36],
        [177, -52],
        [90, -14],
        [67, 14],
        [164, -8],
        [64, 31],
        [28, 54],
        [20, 82],
        [30, 10],
        [88, -3],
        [58, 45],
        [138, 72],
        [66, 79],
        [32, 13],
        [99, -4],
        [74, 19],
        [72, 51],
        [136, 0],
        [98, 26],
        [62, 49],
        [26, 56],
        [43, 29],
        [63, -17],
        [84, 8],
        [59, 23],
        [57, 106],
        [117, 16],
        [64, -1],
      ],
      [
        [9595, 9591],
        [-1, -20],
        [51, -132],
        [27, -171],
        [-16, -31],
        [10, -65],
        [91, -206],
        [28, -89],
        [60, -76],
        [149, -27],
        [15, -33],
        [-17, -43],
        [25, -103],
        [82, -44],
        [30, -55],
        [10, -86],
        [25, -56],
        [59, -57],
        [43, -18],
        [114, 19],
        [43, 66],
        [37, 17],
        [37, -40],
        [9, -57],
        [-25, -69],
        [5, -24],
        [46, -53],
        [63, -23],
        [39, 64],
        [64, 12],
        [25, -45],
        [-5, -52],
        [11, -117],
        [15, -57],
        [8, -125],
        [-7, -32],
        [24, -33],
        [48, -113],
        [106, -272],
        [69, -58],
        [70, -11],
        [92, 4],
        [43, -9],
        [73, 4],
        [123, 25],
        [27, -9],
        [69, -86],
        [50, -50],
        [59, -34],
        [30, 1],
      ],
      [
        [5877, 4288],
        [-30, -130],
        [-37, -54],
        [-4, -71],
        [30, -75],
        [-4, -51],
        [-50, -36],
        [-47, -16],
        [-33, -52],
        [-24, -79],
        [-12, -98],
        [-24, -53],
        [-13, -83],
        [65, -104],
        [60, -36],
        [31, -40],
        [5, -71],
        [-193, 2],
        [-44, -18],
        [-4, -53],
        [17, -56],
        [-64, -29],
        [-13, -35],
        [7, -181],
        [18, -115],
        [0, -144],
        [-49, -45],
        [-76, -21],
        [-108, -93],
        [-109, -12],
        [-75, -23],
        [-30, -20],
        [-25, -55],
        [12, -77],
      ],
      [
        [5054, 2264],
        [-17, -59],
        [54, 11],
        [2, -78],
        [-27, -1],
        [-55, 28],
        [-79, -81],
        [-91, -3],
        [-36, 18],
        [-19, 39],
        [-47, 37],
        [-49, 135],
        [-1, 29],
        [-55, 38],
        [-54, -12],
        [-10, 38],
        [-74, 56],
        [-1, 30],
        [72, 12],
        [26, 21],
        [55, 2],
        [-1, 39],
        [63, 12],
        [7, 68],
        [-37, 28],
        [-2, 49],
        [26, 69],
        [-28, -1],
        [-28, 38],
        [-27, -10],
        [1, -39],
        [-44, -51],
        [-45, -21],
        [-27, 18],
        [-164, -6],
        [-62, -42],
        [-21, 78],
        [-54, -12],
        [-36, 8],
        [16, 59],
        [-28, 19],
        [-73, -4],
        [-113, 103],
        [8, 39],
        [-73, -13],
        [-37, 28],
        [-10, 29],
        [-82, 6],
        [-20, 28],
        [-54, -2],
        [-39, 27],
      ],
      [
        [9595, 9591],
        [52, 37],
        [77, 11],
        [200, -16],
        [79, 61],
        [63, 27],
        [54, -1],
        [246, 156],
        [57, 79],
        [73, 73],
        [90, 69],
        [98, 62],
        [140, 61],
        [95, 85],
        [28, 71],
        [23, 93],
        [64, 116],
        [-3, 45],
        [-58, 85],
        [-51, 60],
        [-58, 87],
        [-44, 29],
        [-18, 105],
        [-24, 71],
        [-62, 44],
        [-46, 63],
        [-12, 58],
        [14, 47],
        [31, 32],
        [121, 67],
        [142, 16],
        [91, 0],
        [146, 95],
        [73, 125],
        [-8, 26],
      ],
      [
        [11268, 11630],
        [-9, 49],
        [51, 37],
        [50, -2],
        [28, -60],
        [109, -14],
        [19, -21],
        [-2, -58],
        [69, -13],
        [-3, -58],
        [19, -40],
        [49, -12],
        [31, 28],
        [40, -2],
        [18, -40],
        [59, -22],
        [8, -40],
        [28, -30],
        [89, -14],
        [59, -23],
        [47, -51],
        [8, -39],
        [57, -61],
        [58, -23],
        [25, -108],
        [6, -69],
        [-5, -107],
        [-21, -9],
        [-4, -77],
        [27, -51],
        [0, -105],
        [36, -113],
        [31, -6],
        [-6, -131],
        [15, -60],
        [52, -54],
        [3, -82],
        [46, -222],
        [41, -179],
        [38, -109],
        [14, -70],
        [51, -132],
        [14, -81],
        [69, -200],
        [52, -22],
        [5, -88],
        [56, -42],
        [5, -79],
        [-30, -28],
        [-3, -58],
        [-22, -37],
        [17, -38],
        [29, -4],
        [22, -142],
        [-4, -48],
        [19, -43],
        [-39, -42],
        [15, -85],
        [-6, -151],
        [19, -36],
        [1, -214],
        [-16, -89],
        [-122, -42],
        [-61, -53],
        [-71, -26],
        [-97, -12],
        [-69, -29],
        [-100, -62],
        [-88, -74],
        [-102, -134],
        [-53, -85],
        [-20, -54],
        [-39, -32],
        [-111, -200],
      ],
      [
        [5884, 11691],
        [61, -38],
        [25, -45],
        [65, -18],
        [-37, -32],
        [-129, 30],
        [-5, 27],
        [19, 3],
        [23, 35],
        [5, 6],
        [0, 7],
        [-27, 25],
      ],
      [
        [6647, 11371],
        [-15, -50],
        [35, -107],
        [41, -76],
        [-8, -44],
        [-31, -62],
        [-79, -80],
        [18, -92],
        [25, -75],
        [42, -51],
        [70, -33],
        [63, 0],
        [28, -35],
        [-31, -83],
        [20, -36],
        [94, 12],
        [83, -6],
        [40, -12],
        [-4, -83],
        [-45, -47],
        [-30, -71],
        [-3, -68],
        [-21, -19],
        [-79, 25],
        [-74, -3],
        [-101, -78],
        [-26, -64],
        [-91, -173],
        [-78, -126],
      ],
      [
        [3663, 9904],
        [-1, 54],
        [-23, 161],
        [7, 40],
        [76, 63],
        [10, 49],
        [-68, 118],
        [32, 44],
        [83, -20],
        [26, 7],
        [-41, 80],
        [-63, 60],
        [-22, 65],
        [-50, 63],
        [-49, 22],
        [3, 126],
        [-13, 118],
        [-29, 101],
        [-25, 44],
        [-30, 106],
        [114, 20],
        [81, 31],
        [-1, 91],
        [-22, 20],
        [-17, 59],
        [58, 63],
        [2, 109],
        [-21, 78],
        [-120, 167],
        [-71, 125],
        [-34, 72],
      ],
      [
        [3455, 12040],
        [149, 43],
        [74, 46],
        [115, 114],
        [112, 53],
        [139, 79],
        [161, 46],
        [129, 11],
        [178, 37],
        [201, 62],
        [70, 14],
        [121, 37],
        [93, 13],
        [124, 6],
        [202, -3],
        [132, 6],
        [37, -38],
        [89, -59],
        [214, -127],
        [91, -48],
        [150, -117],
        [51, -57],
        [-35, -34],
        [-91, 133],
        [-43, 42],
        [-36, 11],
        [-53, 50],
        [-145, 77],
        [-75, 50],
        [-38, -21],
        [-30, -53],
        [-4, -36],
        [36, -19],
        [66, -58],
        [-9, -83],
        [5, -48],
        [58, -4],
        [1, -52],
        [43, -73],
        [19, -68],
        [-21, -18],
        [5, -44],
        [26, -63],
        [-10, -58],
        [16, -36],
        [46, -48],
        [44, -25],
        [21, 5],
        [5, -13],
        [10, -1],
        [10, -6],
        [1, -5],
        [-21, -31],
        [-22, -8],
        [-6, -19],
        [5, -14],
        [41, -25],
        [36, 3],
        [105, -15],
        [27, 32],
        [91, -35],
        [56, -4],
        [32, 32],
        [65, -36],
        [96, 2],
        [171, 16],
        [128, 21],
        [110, 29],
        [125, 48],
        [106, 57],
        [103, 79],
        [12, -12],
        [-73, -63],
        [-72, -43],
        [-70, -29],
        [-93, -54],
        [-80, -34],
        [-72, 1],
        [-92, -32],
        [31, -19],
        [18, -54],
        [-19, -82],
      ],
      [
        [6931, 1274],
        [-71, -18],
        [0, -30],
        [-54, -29],
        [1, -59],
        [-89, -1],
        [-54, -10],
        [-44, -89],
        [1, -88],
        [-44, -20],
        [0, -68],
        [-35, -10],
        [-89, -2],
        [-63, 9],
        [-17, -39],
        [-71, 8],
        [8, 59],
        [-28, 58],
        [-1, 98],
        [-19, 49],
        [-134, -3],
        [8, 88],
        [-18, 10],
        [-19, 68],
        [-1, 88],
        [-19, 87],
        [-19, 19],
        [-80, -1],
        [-28, 19],
        [-9, 39],
        [-63, -11],
        [-36, 18],
        [-1, 88],
        [-67, 184],
        [36, 40],
        [-46, 19],
        [7, 117],
        [-27, -1],
        [-26, -39],
        [-54, -2],
        [-28, 29],
        [-54, 18],
        [-10, 20],
        [-99, 7],
        [-26, -40],
        [-55, 28],
        [-1, 49],
        [-19, 28],
        [-63, -1],
        [-11, 77],
        [-126, -13],
        [-18, 29],
        [8, 39],
        [-19, 29],
        [-55, 37],
        [-36, 9],
      ],
      [
        [12367, 2838],
        [-47, -34],
        [-31, -76],
        [-47, -27],
        [-38, -47],
        [-18, 1],
        [-86, -93],
        [-59, -85],
        [-66, -64],
        [-84, -64],
        [-21, -68],
        [-75, -54],
        [-1, -39],
        [-47, -47],
        [-59, -95],
        [-162, -226],
        [-65, -66],
        [-11, -48],
        [-56, -46],
        [-21, -78],
        [-36, -17],
        [-11, -39],
        [-83, -65],
        [-11, -58],
        [-29, -67],
        [-57, -76],
        [-27, -18],
        [-29, -48],
        [-11, -87],
        [42, -70],
        [-4, -108],
        [33, -79],
        [35, -31],
        [16, -50],
        [34, -31],
        [25, -50],
        [-3, -97],
        [-19, -39],
        [-56, -66],
        [-19, -38],
        [17, -20],
        [-37, -57],
        [53, -2],
        [-3, -69],
        [54, 8],
        [25, -50],
        [51, -52],
        [24, -69],
        [-2, -49],
        [17, -30],
        [-20, -58],
        [-61, 32],
        [-34, 41],
        [-44, -8],
        [-62, 13],
        [-53, -18],
        [-43, 41],
        [1, 30],
        [-52, 41],
        [-45, -8],
        [-79, 3],
        [-44, 22],
        [-71, 2],
        [-25, 31],
        [-25, 59],
        [-53, 2],
        [-19, -28],
        [-52, 31],
        [-62, 2],
        [2, 69],
        [-80, 3],
        [-34, 40],
        [-54, 2],
        [-52, 21],
        [-35, 31],
        [2, 87],
        [-17, 40],
        [-51, 60],
        [-25, 79],
        [-89, 13],
        [-17, 40],
        [-35, 1],
        [-17, 39],
        [-63, -27],
        [-19, -39],
        [-43, 70],
        [-35, 20],
        [1, 30],
        [-81, 2],
        [-25, 59],
        [-54, -18],
        [-89, -7],
        [-27, -26],
      ],
      [
        [6647, 11371],
        [39, 29],
        [143, -33],
        [76, 56],
        [63, 67],
        [47, 12],
        [33, 26],
        [91, 6],
        [44, 24],
        [58, 65],
        [18, 47],
        [29, 22],
        [13, 43],
        [49, 6],
        [132, -18],
        [423, -44],
        [416, -78],
        [238, -28],
        [124, -3],
        [96, -14],
        [200, -11],
        [204, -23],
        [345, -32],
        [309, 18],
        [511, 15],
        [311, 23],
        [225, 5],
        [305, 23],
        [46, 9],
        [33, 47],
      ],
      [
        [282, 10513],
        [62, -56],
        [-63, -30],
        [-8, -36],
        [-53, 64],
        [62, 58],
      ],
      [
        [129, 10644],
        [72, -33],
        [-34, -30],
        [29, -59],
        [11, -51],
        [33, -62],
        [-46, 10],
        [-74, 95],
        [-15, 105],
        [24, 25],
      ],
      [
        [779, 10752],
        [45, -18],
        [0, -40],
        [-71, -12],
        [26, 70],
      ],
      [
        [830, 10851],
        [8, -30],
        [-87, -28],
        [-9, -132],
        [-78, -94],
        [-24, -61],
        [1, -75],
        [-57, -13],
        [1, 51],
        [-48, 21],
        [-149, 17],
        [4, 44],
        [-65, 5],
        [16, -45],
        [-14, -24],
        [-52, 36],
        [-61, -31],
        [-8, 51],
        [-35, 29],
        [39, 53],
        [68, -23],
        [94, 19],
        [66, 46],
        [55, 53],
        [219, 85],
        [70, 39],
        [46, 7],
      ],
      [
        [629, 7754],
        [-2, 21],
        [-51, 45],
        [-131, 67],
        [-13, 48],
        [-44, 33],
        [-65, 95],
        [-70, 62],
        [-80, 42],
        [-54, 74],
        [-98, 11],
        [-21, 64],
        [43, 53],
        [-17, 177],
        [79, 60],
        [87, 42],
        [90, 86],
        [25, 41],
        [30, 103],
        [5, 75],
        [-21, 19],
        [11, 100],
        [29, 11],
        [60, 102],
        [-31, 47],
        [-12, 72],
        [8, 31],
        [-27, 74],
        [2, 39],
        [-36, 105],
        [3, 72],
        [-21, 64],
        [-53, 73],
        [-10, 151],
        [11, 41],
        [-39, 49],
        [3, 55],
        [-24, 86],
        [40, 1],
        [-4, 94],
        [40, -21],
        [21, -47],
        [79, -70],
        [64, -9],
        [78, -25],
        [31, -37],
        [56, -107],
        [11, 61],
        [29, 11],
        [4, 42],
        [-59, 16],
        [-39, 41],
        [-6, 55],
        [37, 136],
        [59, -1],
        [0, 90],
        [9, 55],
        [42, 146],
        [23, 11],
        [45, 61],
        [78, 33],
        [0, 104],
        [46, 29],
        [26, 73],
        [120, 35],
        [187, 80],
        [41, 9],
        [184, 71],
        [66, 30],
        [196, 44],
        [63, 0],
        [95, 46],
        [32, -1],
        [166, 43],
        [190, 77],
        [187, 25],
        [86, 24],
        [71, 30],
        [117, 84],
        [124, 127],
        [89, 132],
        [31, 28],
        [34, 64],
        [76, 84],
        [59, 48],
        [85, 33],
        [121, 30],
        [98, -2],
        [32, 13],
      ],
    ],
    transform: {
      scale: [0.0007712924081699014, 0.0004616903524783179],
      translate: [14.122618675232049, 49.015716552734375],
    },
    objects: {
      POL_adm1: {
        type: "GeometryCollection",
        geometries: [
          {
            arcs: [[0, 1, 2, 3, 4, 5]],
            type: "Polygon",
            properties: {
              ISO: "POL",
              NAME_0: "Poland",
              ID_1: 1,
              NAME_1: "Łódź",
              TYPE_1: "Województwo",
              ENGTYPE_1: "Voivodeship|Province",
              VARNAME_1: "Lódzkie",
            },
          },
          {
            arcs: [[6, 7, 8, 9, -1, 10]],
            type: "Polygon",
            properties: {
              ISO: "POL",
              NAME_0: "Poland",
              ID_1: 2,
              NAME_1: "Świętokrzyskie",
              TYPE_1: "Województwo",
              ENGTYPE_1: "Voivodeship|Province",
              VARNAME_1: "Świętokrzyskie",
            },
          },
          {
            arcs: [[11, 12, -4, 13, 14, 15, 16]],
            type: "Polygon",
            properties: {
              ISO: "POL",
              NAME_0: "Poland",
              ID_1: 3,
              NAME_1: "Greater Poland",
              TYPE_1: "Województwo",
              ENGTYPE_1: "Voivodeship|Province",
              VARNAME_1: "Wielkopolskie",
            },
          },
          {
            arcs: [[17, 18, -5, -13, 19]],
            type: "Polygon",
            properties: {
              ISO: "POL",
              NAME_0: "Poland",
              ID_1: 4,
              NAME_1: "Kuyavian-Pomeranian",
              TYPE_1: "Województwo",
              ENGTYPE_1: "Voivodeship|Province",
              VARNAME_1: "Kujawsko-Pomorskie",
            },
          },
          {
            arcs: [[20, 21, 22, -9]],
            type: "Polygon",
            properties: {
              ISO: "POL",
              NAME_0: "Poland",
              ID_1: 5,
              NAME_1: "Lesser Poland",
              TYPE_1: "Województwo",
              ENGTYPE_1: "Voivodeship|Province",
              VARNAME_1: "Małopolskie",
            },
          },
          {
            arcs: [[-15, 23, 24, 25]],
            type: "Polygon",
            properties: {
              ISO: "POL",
              NAME_0: "Poland",
              ID_1: 6,
              NAME_1: "Lower Silesian",
              TYPE_1: "Województwo",
              ENGTYPE_1: "Voivodeship|Province",
              VARNAME_1: "Dolnośląskie",
            },
          },
          {
            arcs: [[26, 27, -7, 28, 29]],
            type: "Polygon",
            properties: {
              ISO: "POL",
              NAME_0: "Poland",
              ID_1: 7,
              NAME_1: "Lublin",
              TYPE_1: "Województwo",
              ENGTYPE_1: "Voivodeship|Province",
              VARNAME_1: "Lubelskie",
            },
          },
          {
            arcs: [[-16, -26, 30, 31]],
            type: "Polygon",
            properties: {
              ISO: "POL",
              NAME_0: "Poland",
              ID_1: 8,
              NAME_1: "Lubusz",
              TYPE_1: "Województwo",
              ENGTYPE_1: "Voivodeship|Province",
              VARNAME_1: "Lubuskie",
            },
          },
          {
            arcs: [[-29, -11, -6, -19, 32, 33]],
            type: "Polygon",
            properties: {
              ISO: "POL",
              NAME_0: "Poland",
              ID_1: 9,
              NAME_1: "Masovian",
              TYPE_1: "Województwo",
              ENGTYPE_1: "Voivodeship|Province",
              VARNAME_1: "Mazowieckie",
            },
          },
          {
            arcs: [[-3, 34, 35, -24, -14]],
            type: "Polygon",
            properties: {
              ISO: "POL",
              NAME_0: "Poland",
              ID_1: 10,
              NAME_1: "Opole",
              TYPE_1: "Województwo",
              ENGTYPE_1: "Voivodeship|Province",
              VARNAME_1: "Opolskie",
            },
          },
          {
            arcs: [[-30, -34, 36, 37]],
            type: "Polygon",
            properties: {
              ISO: "POL",
              NAME_0: "Poland",
              ID_1: 11,
              NAME_1: "Podlachian",
              TYPE_1: "Województwo",
              ENGTYPE_1: "Voivodeship|Province",
              VARNAME_1: "Podlaskie",
            },
          },
          {
            arcs: [[[38]], [[39, -20, -12, 40, 41]]],
            type: "MultiPolygon",
            properties: {
              ISO: "POL",
              NAME_0: "Poland",
              ID_1: 12,
              NAME_1: "Pomeranian",
              TYPE_1: "Województwo",
              ENGTYPE_1: "Voivodeship|Province",
              VARNAME_1: "Pomorskie",
            },
          },
          {
            arcs: [[-10, -23, 42, -35, -2]],
            type: "Polygon",
            properties: {
              ISO: "POL",
              NAME_0: "Poland",
              ID_1: 13,
              NAME_1: "Silesian",
              TYPE_1: "Województwo",
              ENGTYPE_1: "Voivodeship|Province",
              VARNAME_1: "Śląskie",
            },
          },
          {
            arcs: [[43, -21, -8, -28]],
            type: "Polygon",
            properties: {
              ISO: "POL",
              NAME_0: "Poland",
              ID_1: 14,
              NAME_1: "Subcarpathian",
              TYPE_1: "Województwo",
              ENGTYPE_1: "Voivodeship|Province",
              VARNAME_1: "Podkarpackie",
            },
          },
          {
            arcs: [[-37, -33, -18, -40, 44]],
            type: "Polygon",
            properties: {
              ISO: "POL",
              NAME_0: "Poland",
              ID_1: 15,
              NAME_1: "Warmian-Masurian",
              TYPE_1: "Województwo",
              ENGTYPE_1: "Voivodeship|Province",
              VARNAME_1: "Warmińsko-Mazurskie",
            },
          },
          {
            arcs: [[[45]], [[46]], [[47]], [[48]], [[-17, -32, 49, -41]]],
            type: "MultiPolygon",
            properties: {
              ISO: "POL",
              NAME_0: "Poland",
              ID_1: 16,
              NAME_1: "West Pomeranian",
              TYPE_1: "Województwo",
              ENGTYPE_1: "Voivodeship|Province",
              VARNAME_1: "Zachodniopomorskie",
            },
          },
        ],
      },
    },
  },
};
export default mapdata;
