import React, { useEffect } from "react";
import "./cssStyles/Contact.css";
import Form from "react-bootstrap/Form";
import { BsTelephone } from "react-icons/bs";
import { FiMapPin } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";
import emailjs from "@emailjs/browser";
import { useLocation } from "react-router-dom";

function Contact() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  const send = (e) => {
    e.preventDefault();
    alert("Wysłano wiadomość");
    emailjs.sendForm(
      "service_ovbnnfz",
      "template_yvc336w",
      e.target,
      "KRGbjqXt3HoipBMy9"
    );
    document.getElementById("formularz").reset();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <div className="container col-xl-10 col-xxl-8 px-4 py-5 testt" id="contact">
      <div className="row align-items-center g-lg-5 py-5 ">
        <div className="col-lg-7 text-center text-lg-start cont-style">
          <h1 className="display-4 fw-bold lh-1 mb-3 ">
            Skontaktuj się z nami!
          </h1>
          <h3 className="display-7 fw-bold lh-2 mb-4 ">
            CK-Tronic Serwis Aparatury Medycznej
          </h3>
          <p className="col-lg-10 fs-4 lead">
            <p>
              <BsTelephone className="s-tyle"></BsTelephone>Telefon: 694 428 036
            </p>
            <p>
              <FiMapPin className="s-tyle"></FiMapPin>
              Adres: Wola Kopcowa, ul. Wspólna 81, 26-001
            </p>
            <p>
              <AiOutlineMail className="s-tyle"></AiOutlineMail>Email:
              serwis@ck-tronic.pl
            </p>
          </p>
        </div>
        <div className="col-md-10 mx-auto col-lg-5 ">
          <form
            className="p-4 p-md-5 border rounded-3 bg-light"
            onSubmit={(e) => send(e)}
            id="formularz"
            name="formularz"
          >
            <div className="form-floating mb-3">
              <Form.Control
                required
                type="text"
                placeholder="Imię"
                id="imie"
                name="imie"
              />
              <Form.Label>Imię</Form.Label>
              <Form.Control.Feedback>OK!</Form.Control.Feedback>
            </div>
            <div className="form-floating mb-3">
              <Form.Control
                required
                type="text"
                placeholder="Nazwisko"
                id="nazwisko"
                name="nazwisko"
              />
              <Form.Label>Nazwisko</Form.Label>
              <Form.Control.Feedback>OK!</Form.Control.Feedback>
            </div>
            <div className="form-floating mb-3">
              <Form.Control
                required
                type="email"
                placeholder="Email"
                id="email_from"
                name="email_from"
              />
              <Form.Label>Email</Form.Label>
              <Form.Control.Feedback>OK!</Form.Control.Feedback>
            </div>
            <div className="form-floating mb-3">
              <Form.Control
                required
                as="textarea"
                className="textareaa"
                placeholder="Wiadomość"
                id="message"
                name="message"
              />
              <Form.Label>Wiadomość</Form.Label>
              <Form.Control.Feedback>OK!</Form.Control.Feedback>
            </div>

            <button className="w-100 btn btn-lg btn-primary" type="submit">
              Wyślij
            </button>
            <hr className="my-4" />
          </form>
        </div>
        <div className="p-4 p-md-5 border rounded-3 bg-light">
          <div id="map-container-2" className="z-depth-1-half map-container-3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2517.1143469281424!2d20.716122076582973!3d50.884593971678456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471827a99eee4b89%3A0xfd79570098339ced!2sCK-Tronic!5e0!3m2!1spl!2spl!4v1696957156938!5m2!1spl!2spl"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
