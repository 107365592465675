import Carousel from "react-bootstrap/Carousel";
import "./cssStyles/GalleryCarousel.css";

const GalleryCarousel = () => {
  return (
    <Carousel className="carousel slide carousel-fade" indicators={false}>
      <Carousel.Item interval={6000}>
        <img
          className="d-block w-100 img-fluid"
          src="/images/components.jpg"
          alt="First slide"
        />
        <Carousel.Caption className="text-left">
          <h1 className="display-4 fw-bold lh-3 mb-3">
            Serwis aparatury medycznej
          </h1>
          <p className="h5">
            Jesteśmy, gotowi zadbać o sprawność i bezpieczeństwo Twoich
            urządzeń.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className="blur" interval={3000}>
        <img
          className="d-block w-100"
          src="/images/plyta.jpg"
          alt="First slide"
        />
        <Carousel.Caption className="text-left">
          {/* <h1>Example headline.</h1>
          <p>Opis opis opis</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="/images/Biomed.jpg"
          alt="First slide"
        />
        <Carousel.Caption className="text-left">
          {/* <h1>Example headline.</h1>
          <p>Opis opis opis</p> */}
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};
export default GalleryCarousel;
