import "./App.css";
import Navigation from "./components/Navbar";
import GalleryCarousel from "./components/GalleryCarousel";
import Contact from "./components/Contact";
import About from "./components/about_us";
import Map from "./components/PLmap";
import Footer from "./components/footer";
import Gallery from "./components/galera";

import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  // jsx
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <div className="App">
              <header>
                <Navigation />
              </header>
              <main id="home">
                <GalleryCarousel />
                <div className="container-about-style">
                  <About />
                  <div className="test-map">
                    <Map />
                  </div>
                  <Gallery />
                </div>
                <Contact />
              </main>
              <footer>
                <div className="footer-content bg-dark noise2">
                  <Footer />
                </div>
              </footer>
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

// exportuj funkcje do innych plikow
export default App;
