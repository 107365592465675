import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./cssStyles/galera.css";
import {isMobile} from 'react-device-detect'

const Gallery = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const images = [
    "images/something2.jpg",
    "images/lampa.png",
    "images/stanowisko.png",
    "images/medical.png",
    "images/klaw.jpg",
    "images/something1.jpg",
    
    
  ];

  const openModal = (image) => {
    if(!isMobile){
      setSelectedImage(image);
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedImage("");
  };

  return (
    <div className="container px-4 py-5" id="gallery">
      <h2 className="pb-2 border-bottom">Galeria</h2>

      <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
        {images.map((image, index) => (
          <div key={index} className="col">
            <div
              className="card-cover overflow-hidden rounded-5 shadow-lg item"
              onClick={() => openModal(image)}
            >
              <img
                src={image}
                className="itemimg"
                alt={`Image ${index}`}  
              />
            </div>
          </div>
        ))}
      </div>

      <Modal show={showModal} onHide={closeModal} size="xl">
        <Modal.Body>
          <img
            src={selectedImage}
            className="img-fluid"
            alt="Selected"
            style={{ width: "100%" }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Gallery;
