import { Navbar, Nav, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./cssStyles/Navbar.css";
import { Link } from "react-router-dom";

const Navigation = () => {
  return (
    <>
      <Navbar
        bg="collapse bg-dark fixed-top noise"
        expand="lg"
        data-bs-theme="dark"
      >
        <Container>
          <Navbar.Brand href="#home" className="test">
            <a className="navbar-brand" href="#">
              <img src="/images/logoT.webp" width="62" height="62" alt="" />
              <img
                src="/images/napisT.webp"
                width="80"
                height="40"
                alt=""
                className="sec-img"
              />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navbar-links">
              <Nav.Link
                as={Link}
                to="/"
                className="me-4"
              >
                Start
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/#about"
                className="me-4"
              >
                O nas
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/#gallery"
                className="me-4"
              >
                Galeria
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/#contact"
                className="me-4"
              >
                Kontakt
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Navigation;
