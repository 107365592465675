import React from "react";
import "./cssStyles/footer.css";
import {Link} from 'react-router-dom'

const Footer = () => {
  return (
    <div className="container bg-dark noise2" data-bs-theme="dark">
      <footer className="py-2">
        <ul className="nav justify-content-center border-bottom pb-3 mb-3">
          <li className="nav-item">
            <a href="#" className="nav-link px-2 text-muted">
              Start
            </a>
          </li>
          <li className="nav-item">
            <Link to="/#about"className="nav-link px-2 text-muted">
              O nas
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/#gallery" className="nav-link px-2 text-muted">
              Galeria
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/#contact" className="nav-link px-2 text-muted">
              Kontakt
            </Link>
          </li>
        </ul>
        <p className="footer-text-center text-muted prod-styl">
          &copy; 2023 Created by{" "}
          <a
            href="https://blacksmith-stone.github.io/Homepage/"
            target="_blank"
            rel="noopener noreferrer"
          >
            BSS
          </a>
        </p>
      </footer>
    </div>
  );
};

export default Footer;
