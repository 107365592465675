import React, { useEffect, useRef, useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import mapdata from "./mapdata";
import { geoCentroid } from "d3-geo";
import { gsap } from "gsap";
import "./cssStyles/PLmap.css";

const Map = () => {
  const comp = useRef();
  const ref = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    // Funkcja obsługująca animację
    const runAnimation = () => {
      let ctx = gsap.context(() => {
        gsap.from(".theState", {
          duration: 0.6,
          stagger: 0.15,
          ease: "back",
          y: 96,
          opacity: 0,
        });
        gsap.from(".theMarkers", {
          duration: 0.6,
          stagger: 0.15,
          opacity: 0,
        });
        let tl = gsap.timeline();
        tl.to(".theCountry", {
          delay: 5.4,
          duration: 0.8,
        });
      }, comp);

      return () => ctx.revert();
    };

    // Zdarzenie load
    //window.addEventListener("load", runAnimation);
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    });
    observer.observe(ref.current);
    if (isIntersecting === true && isShown === false) {
      runAnimation();
      setIsShown(true);
    }
    // Czyszczenie zdarzenia po odmontowaniu komponentu
    return () => {
      window.removeEventListener("load", runAnimation);
      observer.disconnect();
    };
  }, [isIntersecting]);

  return (
    <div className="container col-xxl-8 px-4 py-2" ref={ref}>
      <div className="row flex-lg-row align-items-center g-5 py-2">
        <div className="map-container col-10 col-sm-8 col-lg-6">
          <ComposableMap
            onClick={null}
            className="Test"
            projection="geoMercator"
            projectionConfig={{
              scale: 3000,
              center: [19, 52],
            }}
            fill="#eee"
            stroke="black"
            style={{
              outline: "none", // Dodaj ten styl
            }}
          >
            <Geographies geography={mapdata.data} ref={comp}>
              {(geographies) => {
                return (
                  <>
                    {geographies.geographies.map((geo) => {
                      return (
                        <Geography
                          tabIndex={-1}
                          key={geo.rsmKey}
                          className="theState theCountry"
                          geography={geo}
                          style={{
                            // hover: {
                            //   fill: "#abb8c3",
                            //   stroke: "none",
                            // },
                            default: { outline: "none" },
                            hover: { outline: "none" },
                            pressed: { outline: "none" },
                          }}
                        />
                      );
                    })}

                    {geographies.geographies.map((geo) => {
                      const provinceCenter = geoCentroid(geo);
                      let colorFill = "black";
                      let customPlacement = [15.77209, 53.65369];

                      return (
                        <Marker
                          key={geo.rsmKey}
                          coordinates={
                            geo.properties.VARNAME_1 === "Zachodniopomorskie"
                              ? customPlacement
                              : provinceCenter
                          }
                          className="theMarkers"
                        >
                          <text
                            style={{
                              fill: colorFill,
                              strokeWidth: 0,
                            }}
                            textAnchor="middle"
                          >
                            {geo.properties.VARNAME_1}
                          </text>
                        </Marker>
                      );
                    })}
                  </>
                );
              }}
            </Geographies>
          </ComposableMap>
        </div>
        <div className="col-lg-6">
          <h1 className="display-5 fw-bold lh-1 mb-3">
            Działamy w całej polsce
          </h1>
          <p className="lead h6 p-content">
            Firma specjalizuje się w świadczeniu wysokiej jakości usług
            serwisowych dla urządzeń medycznych, działając z pełnym
            zaangażowaniem na terenie Polski. Jesteśmy gotowi sprostać
            najbardziej wymagającym potrzebom naszych klientów, zapewniając
            kompleksową obsługę techniczną. Nasza obecność na polskim rynku
            oznacza, że jesteśmy blisko naszych partnerów biznesowych, gotowi
            nie tylko naprawić urządzenia, ale także zaoferować wsparcie i
            doradztwo, które przyczynią się do efektywnego funkcjonowania
            placówek medycznych w całym kraju.
          </p>
        </div>
      </div>
    </div>
  );
};
export default Map;
